import styled, { useTheme } from "styled-components";
import ReactDOM from "react-dom";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { motion } from "framer-motion";
import Header from "./Header";
import React, { useEffect, useState } from "react";
import prism from "../assets/svg/prism1.svg";
import sparkles from "../assets/svg/sparkles.svg";
import prismNoStroke from "../assets/svg/prism2.svg";
import prismFrontStrokes from "../assets/svg/FrontStroke.svg";
import chatbubble from "../assets/svg/chatbubble.svg";
import chatline from "../assets/svg/chatline.svg";
import logodark from "../assets/svg/logodark.svg";
import logolight from "../assets/svg/logolight.svg";
import RecentNewsletters from "../components/RecentNewsletters";
import Achievements from "../components/Achievements";
import Portfolio from "./Portfolio";
import OurGoals from "./OurGoals";
import TeamContact from "./TeamContact";
import SplashMenu from "./SplashMenu";
import WhoAreWe from "./WhoAreWe";
import BigPrism from "./BigPrism";
import Footer from "./Footer";

const Page = styled.div`
  // height: 200vh;
  width: 100%;
  background-color: ${(props) => props.theme.body};
  transition: all 0.5s ease;
`;
const MenuContainer = styled.div`
  display: flex;
  height: 80vh;
  flex-direction: row;
  align-items: flex-end;
  transition: all 0.5s ease;
  // background-color: blue;
  padding: 0px 80px;
  position: relative;
`;
const Menu = styled(motion.header)`
  display: flex;
  flex-direction: column;
  // align-items: center;
  justify-contect: center;
  margin: 0px;
  list-style: none;
  width: 170px;
  position: relative;
  // background-color: red;
  z-index: 999;
`;
const MenuItem = styled(motion.li)`
  padding: 16px 32px;
  cursor: pointer;
  color: ${(props) => props.theme.text};
  position: relative;
  z-index: 999;
`;
const BigPrismContainer = styled.div`
  position: absolute;
  z-index: 0;
  top: 9%;
  left: 50%;
`;
const SmallPrismContainer = styled(motion.div)`
  position: absolute;
  top: 95%;
  left: 20%;
`;
const LogoContainer = styled(motion.div)`
  position: absolute;
  z-index: 0;
  top: 15%;
  padding: 0px 0px;
`;
const list = ["Welcome", "Who Are We", "Contact Us", "Portfolio", "Goals"];
function Splash(props) {
  function changeTheme() {
    if (props.theme === "light") {
      props.setTheme("dark");
    } else {
      props.setTheme("light");
    }
  }
  function scrollTo(route) {
    if (route === "Welcome") {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    } else if (route === "Who Are We") {
      window.scroll({
        top: 400,
        left: 0,
        behavior: "smooth",
      });
    } else if (route === "Contact Us") {
      window.scroll({
        top: 600,
        left: 0,
        behavior: "smooth",
      });
    } else if (route === "Portfolio") {
      window.scroll({
        top: 1200,
        left: 0,
        behavior: "smooth",
      });
    } else if (route === "Goals") {
      window.scroll({
        top: 2000,
        left: 0,
        behavior: "smooth",
      });
    }
  }
  function getWindowDimensions() {
    const { innerHeight: height } = window;
    return {
      height,
    };
  }

  function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
      getWindowDimensions()
    );

    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
  }
  const theme = useTheme();
  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset);
  // const height = "100vh" + "5px";
  const { height } = useWindowDimensions();
  const [width, setWidth] = React.useState(window.innerWidth);
  const breakpoint = 600;
  const breakpointM = 1100;
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  React.useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);

    // Return a function from the effect that removes the event listener
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0, type: "tween", stiffness: 10, duration: 0.5 }}
      style={{ overflowX: "hidden" }}>
      <Page>
        <Header
          theme={props.theme}
          setTheme={changeTheme}
          desktop={width > breakpoint}
        />
        {/* <SplashMenu theme={props.theme}/> */}
        <MenuContainer
          style={{
            alignItems: offsetY > 440 ? "flex-end" : "flex-end",
            height: height - 200,
          }}>
          {width > breakpoint ? (
            <Menu
              style={{
                position: offsetY > height - 100 - 388 ? "fixed" : "relative",
                top: 0,
                paddingTop: 100,
              }}
              initial={{ x: -200 }}
              animate={{ x: 0 }}
              transition={{ delay: 0.4 }}>
              {list.map((route) => {
                return (
                  <a
                    href={"#" + route}
                    style={{ textDecoration: "none" }}
                    onClick={() => {
                      scrollTo(route);
                    }}>
                    <MenuItem
                      whileHover={{ scale: 1.05 }}
                      className={
                        theme.text === "#FAFAFA" &&
                        route === "Welcome" &&
                        offsetY < 400
                          ? "menuitem-active"
                          : theme.text != "#FAFAFA" &&
                            route === "Welcome" &&
                            offsetY < 400
                          ? "menuitem-active-light"
                          : theme.text === "#FAFAFA" &&
                            route === "Who Are We" &&
                            offsetY >= 400 &&
                            offsetY < 600
                          ? "menuitem-active"
                          : theme.text != "#FAFAFA" &&
                            route === "Who Are We" &&
                            offsetY >= 400 &&
                            offsetY < 600
                          ? "menuitem-active-light"
                          : theme.text === "#FAFAFA" &&
                            route === "Contact Us" &&
                            offsetY >= 600 &&
                            offsetY < 1200
                          ? "menuitem-active"
                          : theme.text != "#FAFAFA" &&
                            route === "Contact Us" &&
                            offsetY >= 600 &&
                            offsetY < 1200
                          ? "menuitem-active-light"
                          : theme.text === "#FAFAFA" &&
                            route === "Portfolio" &&
                            offsetY >= 1200 &&
                            offsetY < 2000
                          ? "menuitem-active"
                          : theme.text != "#FAFAFA" &&
                            route === "Portfolio" &&
                            offsetY >= 1200 &&
                            offsetY < 2000
                          ? "menuitem-active-light"
                          : theme.text === "#FAFAFA" &&
                            route === "Goals" &&
                            offsetY >= 2000
                          ? "menuitem-active"
                          : theme.text != "#FAFAFA" &&
                            route === "Goals" &&
                            offsetY >= 2000
                          ? "menuitem-active-light"
                          : "menuitem"
                      }>
                      {route}
                      {/* {console.log(window)} */}
                    </MenuItem>
                  </a>
                );
              })}
            </Menu>
          ) : (
            <div />
          )}
          <BigPrismContainer className="big-prism">
            <motion.div
              style={{
                position: "relative",
                zIndex: 1,
              }}
              // transition={{
              //   duration: 10,
              //   delay: 0.5,
              //   repeatType: "miror",
              //   repeat: Infinity,
              // }}
            >
              <BigPrism theme={theme} />
            </motion.div>
            <img
              src={chatline}
              width={width > breakpoint ? 180 : 90}
              height={width > breakpoint ? 450 : 225}
              style={{
                transform: "rotate(0deg)",
                opacity: props.theme === "light" ? 1 : 1,
                position: "absolute",
                top: 290,
                transform: `translateY(-${offsetY * 0.05}px)`,
                left: width < breakpointM && width > breakpoint ? 153 : 53,
                zIndex: 2,
              }}></img>
            <img
              className="chat-bubble"
              src={chatbubble}
              width={width > breakpoint ? 500 : 250}
              height={width > breakpoint ? 450 : 225}
              style={{
                transform: "rotate(0deg)",
                opacity: props.theme === "light" ? 1 : 1,
                position: "absolute",
                top: 300,
                transform: `translateY(-${offsetY * 0.2}px)`,
                left:
                  width > breakpointM
                    ? -300
                    : width < breakpointM && width > breakpoint
                    ? -200
                    : -124,
                zIndex: 2,
              }}></img>
            <img
              src={sparkles}
              class="arrowpathbig"
              width={700}
              height={700}
              style={{
                transform: "rotate(0deg)",
                opacity: props.theme === "light" ? 1 : 0.25,
                position: "absolute",
                top: -200,
                transform: `translateY(-${offsetY * 0.15}px)`,
                opacity: 0.3,
              }}></img>
            <img
              src={sparkles}
              width={500}
              height={700}
              style={{
                opacity: props.theme === "light" ? 1 : 0.25,
                position: "absolute",
                top: 300,
                left: 100,
                zIndex: 0,
                transform: `translateY(${offsetY * 0.15}px) rotate(180deg)`,
                opacity: 0.3,
              }}></img>
          </BigPrismContainer>
          <SmallPrismContainer
            style={{ transform: `translateY(${offsetY * 0.15}px)` }}>
            <img
              src={prism}
              width={140}
              height={140}
              style={{
                transform: "rotate(-12deg)",
                opacity: props.theme === "light" ? 1 : 0.25,
              }}></img>
          </SmallPrismContainer>
          <LogoContainer
            style={{
              transform: `translateY(-${offsetY * 0.5}px)`,
            }}
            initial={{ x: -500 }}
            animate={{ x: props.tablet ? 80 : props.desktop ? 80 : 0 }}
            transition={{ delay: 0.4 }}>
            <img
              src={theme.text != "#FAFAFA" ? logodark : logolight}
              width={width > breakpoint ? 340 : 200}
              height={140}
              style={{ opacity: 1 }}></img>
          </LogoContainer>
        </MenuContainer>
        <WhoAreWe theme={props.theme} desktop={width > breakpoint} />
        <RecentNewsletters
          id="About"
          theme={props.theme}
          desktop={width > breakpoint}
        />
        {/* <Achievements theme={props.theme} /> */}
        <Portfolio
          id="Portfolio"
          theme={props.theme}
          desktop={width > breakpoint}
          tablet={width < breakpointM && width > breakpoint}
        />
        <OurGoals
          id="Goals"
          theme={props.theme}
          desktop={width > breakpoint}
          tablet={width < breakpointM && width > breakpoint}
        />
        {/* <TeamContact theme={props.theme} /> */}
        <Footer theme={props.theme} desktop={width > breakpoint} />
      </Page>
    </motion.div>
  );
}
export default Splash;
