import logo from "./logo.svg";
import { useEffect, useState } from "react";
import Splash from "./components/Splash";
import "./App.css";
import { ThemeProvider } from "styled-components";
import Header from "./components/Header";
import { AnimatePresence } from "framer-motion";
// import WhoAreWe from './components/WhoAreWe';

const lightTheme = {
  body: "#FAFAFA",
  white: "#FFFFFF",
  text: "#212227",
  gray4: "#BDBDBD",
  toggleBorder: "#FFF",
  blue: "#2F80ED",
  gradient: "linear-gradient(#39598A, #79D7ED)",
};

const darkTheme = {
  body: "#121315",
  white: "#FFFFFF",
  text: "#FAFAFA",
  gray4: "#BDBDBD",
  toggleBorder: "#6B8096",
  blue: "#2F80ED",
  gradient: "linear-gradient(#091236, #1E215D)",
};

const themes = {
  light: lightTheme,
  dark: darkTheme,
};

function App() {
  const [theme, setTheme] = useState("dark");
  return (
    <ThemeProvider theme={themes[theme]}>
      <AnimatePresence exitBeforeEnter>
        <Splash theme={theme} setTheme={setTheme} />
      </AnimatePresence>
    </ThemeProvider>
  );
}

export default App;
