import { useTheme } from "styled-components";
import { useEffect, useState } from "react";

function Achievements(props) {
  const theme = useTheme();
  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        padding: "20px 0px",
        width: "100%",
        transform: `translateY(-${offsetY * 0.8}px)`,
        marginTop: -100,
      }}>
      <h1
        style={{
          color: props.theme === "light" ? theme.text : theme.white,
          textAlign: "center",
          fontSize: 42,
        }}>
        Achievements / Recent or Past Returns
      </h1>
    </div>
  );
}
export default Achievements;
