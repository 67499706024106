import { useTheme } from "styled-components";
import { useEffect, useState } from "react";
import { motion } from "framer-motion";
function TeamContact(props) {
  const theme = useTheme();
  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        padding: "20px 0px",
        width: "100%",
        transform: `translateY(-${offsetY * 0.1}px)`,
        marginTop: 100,
      }}>
      <h1
        style={{
          color: props.theme === "light" ? theme.text : theme.white,
          textAlign: "center",
          fontSize: 42,
        }}>
        Team / Contact Us
      </h1>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <motion.svg
          style={{
            width: 800,
            height: 300,
          }}
          id="Layer_1"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 343 165">
          <motion.path
            className="arrowpathbig"
            d="M330,22a11,11,0,0,1,11,11v98a11,11,0,0,1-11,11H72.8l-.24.15L43.47,159.93l3.64-16.72.26-1.21H31a11,11,0,0,1-11-11V33A11,11,0,0,1,31,22H330m0-1H31A12,12,0,0,0,19,33v98a12,12,0,0,0,12,12H46.13L42,162l31.08-19H330a12,12,0,0,0,12-12V33a12,12,0,0,0-12-12Z"
            transform="translate(-9 -11)"
            fill="none"
            stroke="#2f80ec"
            stroke-width="2"
          />
          <motion.path
            className="path"
            d="M330,22a11,11,0,0,1,11,11v98a11,11,0,0,1-11,11H72.8l-.24.15L43.47,159.93l3.64-16.72.26-1.21H31a11,11,0,0,1-11-11V33A11,11,0,0,1,31,22H330m0-1H31A12,12,0,0,0,19,33v98a12,12,0,0,0,12,12H46.13L42,162l31.08-19H330a12,12,0,0,0,12-12V33a12,12,0,0,0-12-12Z"
            transform="translate(-9 -11)"
            fill="none"
            stroke="#2f80ec"
            strokeOpacity=".25"
          />
          <motion.path
            className="path"
            d="M339.26,12C345.73,12,351,17.85,351,25V139.72c0,7.2-5.27,13.05-11.74,13.05H66.13l-.26.17-31,20.88L38.79,154l.23-1.19H21.74c-6.47,0-11.74-5.85-11.74-13.05V25c0-7.19,5.27-13,11.74-13H339.26m0-1H21.74C14.73,11,9,17.32,9,25V139.72c0,7.73,5.73,14.05,12.74,14.05H37.81L33.42,176l33-22.23H339.26c7,0,12.74-6.32,12.74-14.05V25c0-7.72-5.73-14-12.74-14Z"
            transform="translate(-9 -11)"
            fill="none"
            stroke="#2f80ec"
            strokeOpacity=".15"
          />
        </motion.svg>
      </div>
    </div>
  );
}
export default TeamContact;
