import styled, { useTheme } from "styled-components";
import { motion, useViewportScroll } from "framer-motion";
import { useState, useEffect } from "react";

const svgVariants = {
  hidden: { rotate: -180 },
  visible: {
    rotate: 0,
    transition: { duration: 1 },
  },
};

const pathVariants = {
  hidden: {
    opacity: 0,
    pathLength: 0,
  },
  visible: {
    opacity: 1,
    pathLength: 1,
    transition: {
      duration: 2,
      ease: "easeInOut",
    },
  },
};

const Page = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100%;
  background-color: ${(props) => props.theme.body};
  transition: all 0.5s ease;
`;
const Nav = styled(motion.header)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-contect: center;
  margin: 0px;
  list-style: none;
  flex: 1;
  width: 100vh;
  height: 50px;
  position: fixed;
`;
const NavItem = styled(motion.li)`
  padding: 16px 32px;
  cursor: pointer;
  color: ${(props) => props.theme.text};
`;
const SwitchContainer = styled(motion.div)`
  display: flex;
  flex-direction: row;
  right: 60px;
  background-color: ${(props) => props.theme.gray4};
  border-radius: 19px;
  width: 60px;
  height: 32px;
  cursor: pointer;
`;
const Toggle = styled(motion.button)`
  height: 30px;
  margin-top: 1px;
  width: 30px;
  border-radius: 50%;
  border: none;
  background-color: ${(props) => props.theme.pageBackground};
  color: ${(props) => props.theme.pageBackground};
  &:focus {
    outline: none;
  }
  transition: all 0.5s ease;
`;

const list = ["Welcome", "About", "Assets", "Research", "Team", "Contact"];

function Header(props) {
  const [isOpen, setIsOpen] = useState(false);
  function changeTheme() {
    if (props.theme === "light") {
      props.setTheme("dark");
    } else {
      props.setTheme("light");
    }
  }
  const theme = useTheme();
  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  const variants = {
    visible: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: -100 },
  };
  return (
    <Page>
      {/* <motion.div className="logo"
                    animate={offsetY > 300  ? "visible" : 'hidden'}
                    variants={variants} 
                    initial={false}
                    // initial={{ y: -100, opacity:0}}
                    // animate={{ y: offsetY > 300 ? 0 : -100 , opacity:1 }}
                    transition={{ delay: 0, type: 'tween', stiffness: 10 , duration:.5}}
                    style={{width:700, height:45, position:'relative', zIndex:999}}
                >
                
                <Nav
                    style={{
                        position:'fixed',
                        backgroundColor:theme.body
                    }}
                >
                    {list.map((route) => {
                        return (
                        <NavItem
                            whileHover={{ scale: 1.05}}
                            onClick={setIsOpen}
                        >
                            {route}{offsetY}{offsetY > 300 ? 't' : 'f'}
                        </NavItem>
                        );
                    })}
                </Nav>
                
                </motion.div> */}
      <div
        style={{
          position: "absolute",
          right: props.desktop ? "80px" : "20px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          zIndex: 999,
        }}>
        {/* <motion.button
          style={{
            marginRight: 20,
            backgroundColor: "transparent",
          }}
          className="topnavbutton"
          initial={{ y: -100 }}
          animate={{ y: 0 }}
          transition={{ duration: 0.5 }}
          // whileHover={{scale:1.05}}
        >
          Client Portal
        </motion.button> */}
        <SwitchContainer
          onClick={changeTheme}
          initial={{ y: -100 }}
          animate={{ y: 0 }}
          transition={{ duration: 0.5, delay: 0.185 }}>
          <Toggle
            animate={{ x: props.theme === "dark" ? "0px" : "28px" }}
            transition={{ type: "tween", duration: 0.05 }}></Toggle>
        </SwitchContainer>
      </div>
    </Page>
  );
}
export default Header;
