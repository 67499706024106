import styled, { useTheme } from "styled-components";
import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import prism from "../assets/svg/prism1.svg";
const WhoAreWeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 100px;
`;
const SmallPrismContainer = styled(motion.div)`
  margin-right: 200px;
`;

const list = ["Welcome", "About", "Portfolio", "Goals", "Team", "Contact"];

function WhoAreWe(props) {
  function changeTheme() {
    if (props.theme === "light") {
      props.setTheme("dark");
    } else {
      props.setTheme("light");
    }
  }
  const theme = useTheme();
  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <WhoAreWeContainer>
      <div
        className={
          props.desktop ? "whoarewe-container" : "whoarewe-container-mobile"
        }
        style={{
          transform: `translateY(-${offsetY * 1.3}px)`,
          backgroundColor: props.theme === "light" ? theme.white : theme.body,
          boxShadow:
            props.theme === "light"
              ? "0px 0px 20px #00000015"
              : "0px 0px 20px #000",
          borderColor: props.theme === "light" ? theme.white : "darkgrey",
        }}>
        <h1
          style={{
            color: props.theme === "light" ? theme.text : theme.white,
          }}>
          Who We Are
        </h1>
        <br />
        <p
          style={{
            color: props.theme === "light" ? theme.text : theme.white,
          }}>
          We believe in the current and future implications of various
          cryptocurrencies, not as just assets of value but as technologies that
          allow for a better tomorrow.
          <br />
          <br />
          Our portfolio reflects this central thesis, and consists of Bitcoin
          and Ethereum custody, lending and yield farming, and active
          investments in up-and-coming DeFi protocols.
        </p>
      </div>
      {/* <SmallPrismContainer style={{transform: `translateY(-${offsetY * .5}px)`}}>
                        <img src={prism} width={140} height={140} style={{transform:'rotate(4deg)', opacity: props.theme === 'light' ? 1 : .25}}></img>
                      </SmallPrismContainer> */}
    </WhoAreWeContainer>
  );
}
export default WhoAreWe;
