import { useTheme } from "styled-components";
import { useEffect, useState } from "react";
import ArrowsUp from "./ArrowsUp";

function OurGoals(props) {
  const theme = useTheme();
  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <div
      className="ourgoals-container "
      style={{
        transform: `translateY(-${offsetY * 1.1 - offsetY}px)`,
        backgroundColor: props.theme === "light" ? theme.white : "transparent",
        boxShadow:
          props.theme === "light"
            ? "0px 0px 20px #00000015"
            : "0px 0px 20px #000",
        borderColor: props.theme === "light" ? theme.white : "darkgrey",
        padding: props.desktop
          ? "80px 100px 80px 250px"
          : "40px 50px 40px 30px",
        marginTop: !props.deskop && 300,
        width: props.desktop ? "40%" : "70%",
      }}>
      <div>
        <h1
          style={{
            color: props.theme === "light" ? theme.text : theme.white,
          }}>
          Our Goals
        </h1>
        <br />
        <p
          style={{
            fontSize: 18,
            color: props.theme === "light" ? theme.text : theme.white,
            lineHeight: 1.3,
          }}>
          <strong>
            1. Do the work
            <br />
            <br />
          </strong>
          As new cryptocurrencies enter the market, it is imperative to become
          experts before making financial decisions. This goes beyond reading a
          whitepaper to ask, "How?" and more importantly, "Why?"
          <br />
          <br />
          <br />
          <br />
          <strong>
            2. Recognize value
            <br />
            <br />
          </strong>
          Many new protocols look picture-perfect on paper but fall apart in
          real-world implementation. Recognizing a protocol's true value
          requires not only expertise in the technology but a fundamental
          understanding of what the world will need in the future.
          <br />
          <br />
          <br />
          <br />
          <strong>
            3. Settle for more
            <br />
            <br />
          </strong>
          Cryptocurrency is our passion. As the space grows limitlessly, so does
          our excitement and investment, whether emotionally or financially.
        </p>
      </div>
      {props.desktop && <ArrowsUp theme={theme} style={{ opacity: 0.25 }} />}
    </div>
  );
}
export default OurGoals;
