import styled, { useTheme } from "styled-components";
import { motion } from "framer-motion";
import prism from "../assets/svg/prism1.svg";
import { useEffect, useState } from "react";
const SmallPrismContainer = styled(motion.div)`
  position: absolute;
  right: opx;
`;

function RecentNewsletters(props) {
  const theme = useTheme();
  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        marginTop: 0,
        position: "relative",
      }}>
      {/* <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: offsetY > 370 ? 1 : offsetY / 370 }}
        transition={{ delay: 0, type: "spring", stiffness: 2000, damping: 20 }}> */}
      <div
        className={
          props.desktop ? "contactus-container" : "contactus-container-mobile"
        }
        style={{
          transform: `translateY(-${offsetY * 1.1}px)`,
          backgroundColor: theme.blue,
          boxShadow:
            props.theme === "light"
              ? "0px 0px 20px #00000015"
              : "0px 0px 20px #000",
        }}>
        <h1
          style={{
            color: theme.white,
          }}>
          Contact Us
        </h1>
        <br />
        {/* <ul className='newsletterlist'>
                          <li className='newsletteritem'
                            style={{
                              color:theme.text
                            }}
                          >
                            <label>
                              Newsletter Name Here...
                            </label>
                            <div>
                              >
                            </div>
                          </li>
                        </ul> */}
        <p
          style={{
            fontSize: 18,
            color: theme.white,
            marginBottom: 32,
          }}>
          Don't be shy, click below or send us an email at
          {props.desktop && (
            <strong style={{ fontSize: 18 }}>
              &nbsp;interested@ramiel.capital
            </strong>
          )}{" "}
          with any questions.
        </p>
        <div>
          <a href={"mailto:contact@checkmatetutor.com"}>
            <motion.button whileHover={{ scale: 1.05 }}>Email us</motion.button>
          </a>
        </div>
        {/* <div
            style={{
              backgroundColor: "transparent",
              border: "2px solid white",
              color: theme.text,
              padding: "8px 16px",
              fontSize: 16,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}>
            <input
              type="text"
              placeholder="Your email..."
              style={{
                backgroundColor: "transparent",
                border: "none",
                color: theme.text,
                fontSize: 16,
              }}
            />
            <motion.button whileHover={{ scale: 1.05 }}>Sign Up</motion.button>
          </div> */}
      </div>
      {/* </motion.div> */}
      <SmallPrismContainer
        style={{
          transform: `translateY(${offsetY * 0.2}px)`,
          marginLeft: 1300,
        }}>
        <img
          src={prism}
          width={140}
          height={140}
          style={{
            transform: "rotate(90deg)",
            opacity: props.theme === "light" ? 1 : 0.25,
          }}></img>
      </SmallPrismContainer>
    </div>
  );
}
export default RecentNewsletters;
