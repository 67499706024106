import styled, { useTheme } from "styled-components";
import ReactDOM from "react-dom";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { motion } from "framer-motion";
import Header from "./Header";
import { useEffect, useState } from "react";
import prism from "../assets/svg/prism1.svg";
import RecentNewsletters from "./RecentNewsletters";
import Achievements from "./Achievements";
import Portfolio from "./Portfolio";
import OurGoals from "./OurGoals";
import TeamContact from "./TeamContact";

const Page = styled.div`
  // height: 200vh;
  width: 100%;
  background-color: ${(props) => props.theme.body};
  transition: all 0.5s ease;
`;
const MenuContainer = styled.div`
  display: flex;
  height: 80vh;
  flex-direction: row;
  align-items: flex-end;
  transition: all 0.5s ease;
  padding: 0px 80px;
  position: "relative";
`;
const Menu = styled(motion.header)`
  display: flex;
  flex-direction: column;
  // align-items: center;
  justify-contect: center;
  margin: 0px;
  list-style: none;
  width: 15%;
`;
const MenuItem = styled(motion.li)`
  padding: 16px 32px;
  cursor: pointer;
  color: ${(props) => props.theme.text};
`;
const BigPrismContainer = styled.div`
  position: absolute;
  top: 15%;
  left: 50%;
`;
const SmallPrismContainer = styled(motion.div)`
  position: absolute;
  top: 80%;
  left: 20%;
`;
const WhoAreWeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 100px;
`;

const list = ["Welcome", "About", "Portfolio", "Goals", "Team", "Contact"];

function SplashMenu(props) {
  function changeTheme() {
    if (props.theme === "light") {
      props.setTheme("dark");
    } else {
      props.setTheme("light");
    }
  }
  const theme = useTheme();
  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <MenuContainer
      style={{
        alignItems: offsetY > 450 ? "flex-start" : "flex-end",
      }}>
      <Menu
        style={{ position: offsetY > 450 ? "fixed" : "relative" }}
        initial={{ x: -200 }}
        animate={{ x: 0 }}
        transition={{ delay: 0.4 }}>
        {list.map((route) => {
          return (
            <AnchorLink href={"#" + route} style={{ textDecoration: "none" }}>
              <MenuItem whileHover={{ scale: 1.05 }}>{route}</MenuItem>
            </AnchorLink>
          );
        })}
      </Menu>
      <BigPrismContainer>
        <motion.div
          whileHover={{ scale: 1.05 }}
          transition={{ duration: 0.25 }}>
          <motion.div
            // animate={{
            //   rotate:['0deg','.5deg', '0deg', '-.5deg', '0deg'],
            //   translateX:[0,5, 0, -5, 0],
            //   translateY:[0,5, 0 , -5, 0],
            // }}
            transition={{
              duration: 10,
              delay: 0.5,
              repeatType: "miror",
              repeat: Infinity,
            }}>
            <motion.svg
              initial={{ scale: 1, rotate: "-10deg" }}
              animate={{ scale: 1.1, rotate: "0deg" }}
              transition={{ duration: 0.5, delay: 0.1 }}
              id="Layer_2"
              data-name="Layer 2"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 337.14 342.15"
              style={{ width: 700, height: 700 }}>
              <motion.path
                class="cls-1 path"
                stroke="#3fa9f5"
                fill="transparent"
                strokeWidth="1"
                strokeDasharray="50"
                d="M188,10,348,182S190.1,351.12,189.3,351.31s43-132.7,43-132.7l-219-27.86"
                transform="translate(-11.55 -9.66)"
              />
              <motion.path
                class="cls-1 path"
                stroke="#3fa9f5"
                fill="transparent"
                strokeWidth="1"
                strokeDasharray="50"
                d="M188.81,351.33,12.27,191.26S187.49,10.5,188.5,10.5s43.77,208.11,43.77,208.11l116.23-37"
                transform="translate(-11.55 -9.66)"
              />
            </motion.svg>
          </motion.div>
        </motion.div>
      </BigPrismContainer>
      <SmallPrismContainer
        style={{ transform: `translateY(-${offsetY * 0.5}px)` }}>
        <img
          src={prism}
          width={140}
          height={140}
          style={{ transform: "rotate(-12deg)", opacity: 0.25 }}></img>
      </SmallPrismContainer>
    </MenuContainer>
  );
}
export default SplashMenu;
