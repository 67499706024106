import { useTheme } from "styled-components";
import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import bitcoin from "../assets/svg/btc.svg";
import eth from "../assets/svg/eth.svg";
import sol from "../assets/svg/sol.svg";
import avax from "../assets/svg/avax.svg";
import ar from "../assets/svg/ar.svg";
import syn from "../assets/svg/syn.png";
import "../assets/styles/PortfolioStyles.css";

function Portfolio(props) {
  const theme = useTheme();
  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          transform: `translateX(-${offsetY * 0.7}px)`,
          marginTop: -500,
        }}>
        <span
          className="bannertext"
          style={{
            color: theme.gray4,
          }}>
          Portfolio
        </span>
        <span
          className="bannertext"
          style={{
            color: theme.gray4,
          }}>
          Portfolio
        </span>{" "}
        <span
          className="bannertext"
          style={{
            color: theme.gray4,
          }}>
          Portfolio
        </span>{" "}
        <span
          className="bannertext"
          style={{
            color: theme.gray4,
          }}>
          Portfolio
        </span>{" "}
        <span
          className="bannertext"
          style={{
            color: theme.gray4,
          }}>
          Portfolio
        </span>{" "}
        <span
          className="bannertext"
          style={{
            color: theme.gray4,
          }}>
          Portfolio
        </span>{" "}
        <span
          className="bannertext"
          style={{
            color: theme.gray4,
          }}>
          Portfolio
        </span>{" "}
        <span
          className="bannertext"
          style={{
            color: theme.gray4,
          }}>
          Portfolio
        </span>
        <span
          className="bannertext"
          style={{
            color: props.theme === "light" ? "black" : "white",
            opacity: 1,
          }}>
          Portfolio
        </span>
        <span
          className="bannertext"
          style={{
            color: theme.gray4,
          }}>
          Portfolio
        </span>
        <span
          className="bannertext"
          style={{
            color: theme.gray4,
          }}>
          Portfolio
        </span>
        <span
          className="bannertext"
          style={{
            color: theme.gray4,
          }}>
          Portfolio
        </span>
        <span
          className="bannertext"
          style={{
            color: theme.gray4,
          }}>
          Portfolio
        </span>
        <span
          className="bannertext"
          style={{
            color: theme.gray4,
          }}>
          Portfolio
        </span>
        <span
          className="bannertext"
          style={{
            color: theme.gray4,
          }}>
          Portfolio
        </span>
      </div>
      <div
        className={
          props.tablet
            ? "portfolios-row-tablet"
            : props.desktop
            ? "portfolios-row"
            : "portfolios-row-mobile"
        }
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
        }}>
        <motion.div
          whileHover={{ scale: 1.1 }}
          style={{
            backgroundColor:
              props.theme === "light" ? "transparent" : theme.body,
            height: props.desktop ? 200 : 120,
            width: props.desktop ? 180 : 100,
            margin: !props.desktop && "0px 8px 12px 8px",
          }}
          class="assettile-dark"
          className={
            props.theme === "light" ? "assettile-light" : "assettile-dark"
          }>
          <img
            src={bitcoin}
            width={props.desktop ? 120 : 60}
            height={props.desktop ? 120 : 60}></img>
          <span
            style={{
              color: theme.text,
              fontSize: 16,
              fontWeight: "600",
              textAlign: "center",
              paddingTop: 20,
            }}>
            Bitcoin
          </span>
        </motion.div>
        <script src="./portfolioscript.js"></script>
        <motion.div
          whileHover={{ scale: 1.1 }}
          style={{
            height: props.desktop ? 200 : 120,
            width: props.desktop ? 180 : 100,
            margin: !props.desktop && "0px 8px 12px 8px",
            backgroundColor:
              props.theme === "light" ? "transparent" : theme.body,
          }}
          className={
            props.theme === "light" ? "assettile-light" : "assettile-dark"
          }>
          <img
            src={eth}
            width={props.desktop ? 120 : 60}
            height={props.desktop ? 120 : 60}></img>
          <span
            style={{
              color: theme.text,
              fontSize: 16,
              fontWeight: "600",
              textAlign: "center",
              paddingTop: 20,
            }}>
            Ethereum
          </span>
        </motion.div>
        <motion.div
          whileHover={{ scale: 1.1 }}
          style={{
            height: props.desktop ? 200 : 120,
            width: props.desktop ? 180 : 100,
            margin: !props.desktop && "0px 8px 12px 8px",
            backgroundColor:
              props.theme === "light" ? "transparent" : theme.body,
          }}
          className={
            props.theme === "light" ? "assettile-light" : "assettile-dark"
          }>
          <img
            src={sol}
            width={props.desktop ? 120 : 60}
            height={props.desktop ? 120 : 60}></img>
          <span
            style={{
              color: theme.text,
              fontSize: 16,
              fontWeight: "600",
              textAlign: "center",
              paddingTop: 20,
            }}>
            Solana
          </span>
        </motion.div>
        <motion.div
          whileHover={{ scale: 1.1 }}
          style={{
            height: props.desktop ? 200 : 120,
            width: props.desktop ? 180 : 100,
            margin: !props.desktop && "0px 8px 12px 8px",
            backgroundColor:
              props.theme === "light" ? "transparent" : theme.body,
          }}
          className={
            props.theme === "light" ? "assettile-light" : "assettile-dark"
          }>
          <img
            src={avax}
            width={props.desktop ? 120 : 60}
            height={props.desktop ? 120 : 60}></img>
          <span
            style={{
              color: theme.text,
              fontSize: 16,
              fontWeight: "600",
              textAlign: "center",
              paddingTop: 20,
            }}>
            Avalanche
          </span>
        </motion.div>
        <motion.div
          whileHover={{ scale: 1.1 }}
          style={{
            height: props.desktop ? 200 : 120,
            width: props.desktop ? 180 : 100,
            margin: !props.desktop && "0px 8px 12px 8px",
            backgroundColor:
              props.theme === "light" ? "transparent" : theme.body,
          }}
          className={
            props.theme === "light" ? "assettile-light" : "assettile-dark"
          }>
          <img
            src={ar}
            width={props.desktop ? 120 : 60}
            height={props.desktop ? 120 : 60}></img>
          <span
            style={{
              color: theme.text,
              fontSize: 16,
              fontWeight: "600",
              textAlign: "center",
              paddingTop: 20,
            }}>
            Arweave
          </span>
        </motion.div>
        <motion.div
          whileHover={{ scale: 1.1 }}
          style={{
            height: props.desktop ? 200 : 120,
            width: props.desktop ? 180 : 100,
            margin: !props.desktop && "0px 8px 12px 8px",
            backgroundColor:
              props.theme === "light" ? "transparent" : theme.body,
          }}
          className={
            props.theme === "light" ? "assettile-light" : "assettile-dark"
          }>
          <img
            src={syn}
            width={props.desktop ? 120 : 60}
            height={props.desktop ? 120 : 60}></img>
          <span
            style={{
              color: theme.text,
              fontSize: 16,
              fontWeight: "600",
              textAlign: "center",
              paddingTop: 20,
            }}>
            Synapse
          </span>
        </motion.div>
        {/* <motion.img  whileHover={{ scale: 1.1 }} src={bitcoin} width={120} height={120} ></motion.img> */}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          transform: `translateX(${offsetY * 0.25}px)`,
          // backgroundColor:theme.body,
          marginLeft: -800,
        }}>
        <span
          style={{
            fontSize: 48,
            fontWeight: "600",
            color: theme.gray4,
            margin: "0px 4px",
            opacity: 0.15,
          }}>
          Portfolio
        </span>
        <span
          style={{
            fontSize: 48,
            fontWeight: "600",
            color: theme.gray4,
            margin: "0px 4px",
            opacity: 0.15,
          }}>
          Portfolio
        </span>
        <span
          style={{
            fontSize: 48,
            fontWeight: "600",
            color: theme.gray4,
            margin: "0px 4px",
            opacity: 0.15,
          }}>
          Portfolio
        </span>
        <span
          style={{
            fontSize: 48,
            fontWeight: "600",
            color: theme.gray4,
            margin: "0px 4px",
            opacity: 0.15,
          }}>
          Portfolio
        </span>
        <span
          style={{
            fontSize: 48,
            fontWeight: "600",
            color: theme.gray4,
            margin: "0px 4px",
            opacity: 0.15,
          }}>
          Portfolio
        </span>
        <span
          style={{
            fontSize: 48,
            fontWeight: "600",
            color: theme.gray4,
            margin: "0px 4px",
            opacity: 0.15,
          }}>
          Portfolio
        </span>
        <span
          style={{
            fontSize: 48,
            fontWeight: "600",
            color: theme.gray4,
            margin: "0px 4px",
            opacity: 0.15,
          }}>
          Portfolio
        </span>
        <span
          style={{
            fontSize: 48,
            fontWeight: "600",
            color: theme.gray4,
            margin: "0px 4px",
            opacity: 0.15,
          }}>
          Portfolio
        </span>
        <span
          style={{
            fontSize: 48,
            fontWeight: "600",
            color: theme.gray4,
            margin: "0px 4px",
            opacity: 0.15,
          }}>
          Portfolio
        </span>
        <span
          style={{
            fontSize: 48,
            fontWeight: "600",
            color: theme.gray4,
            margin: "0px 4px",
            opacity: 0.15,
          }}>
          Portfolio
        </span>
        <span
          style={{
            fontSize: 48,
            fontWeight: "600",
            color: theme.gray4,
            margin: "0px 4px",
            opacity: 0.15,
          }}>
          Portfolio
        </span>
        <span
          style={{
            fontSize: 48,
            fontWeight: "600",
            color: theme.gray4,
            margin: "0px 4px",
            opacity: 0.15,
          }}>
          Portfolio
        </span>
      </div>
    </>
  );
}
export default Portfolio;
