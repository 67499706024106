import { useTheme } from "styled-components";
import { useEffect, useState } from "react";
import { motion } from "framer-motion";

function ArrowsUp(props) {
  const theme = useTheme();
  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <>
      <motion.svg
        transition={{ duration: 0.5, delay: 0.1 }}
        style={{
          width: 500,
          height: 700,
          position: "absolute",
          top: 80,
          right: -600,
        }}
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 266.53 310.52">
        <motion.polygon
          class="arrowpathbig"
          id="BottomRight"
          points="224.97 309.02 143.43 169.45 99.9 244.81 224.97 309.02"
          fill="none"
          stroke="#2f80ec"
          stroke-linejoin="round"
          stroke-width="1"
          fill-rule="evenodd"
        />
        <motion.polygon
          class="arrowpathbig"
          id="BottomLeft"
          points="1.5 244.38 99.9 244.81 143.43 169.45 1.5 244.38"
          fill="none"
          stroke="#2f80ec"
          stroke-linejoin="round"
          stroke-width="1"
          fill-rule="evenodd"
        />
        <motion.polygon
          class="arrowpathbig"
          id="MiddleRight"
          points="265.03 170.51 169.44 74.59 150.07 144.88 265.03 170.51"
          fill="none"
          stroke="#2f80ec"
          stroke-linejoin="round"
          stroke-width="1"
          fill-rule="evenodd"
        />
        <motion.polygon
          class="arrowpathbig"
          id="MiddleLeft"
          points="70.21 165.32 150.07 144.88 169.44 74.59 70.21 165.32"
          fill="none"
          stroke="#2f80ec"
          stroke-linejoin="round"
          stroke-width="1"
          fill-rule="evenodd"
        />
        <motion.polygon
          class="arrowpathbig"
          id="TopRight"
          points="258.39 61.97 174.56 1.5 168.64 56.83 258.39 61.97"
          fill="none"
          stroke="#2f80ec"
          stroke-linejoin="round"
          stroke-width="1"
          fill-rule="evenodd"
        />
        <motion.polygon
          class="arrowpathbig"
          id="TopLeft"
          points="111 82.07 168.64 56.83 174.56 1.5 111 82.07"
          fill="none"
          stroke="#2f80ec"
          stroke-linejoin="round"
          stroke-width="1"
          fill-rule="evenodd"
        />
        <motion.polygon
          class="arrowpathsmall"
          id="BottomRight-2"
          data-name="BottomRight"
          points="224.97 309.02 143.43 169.45 99.9 244.81 224.97 309.02"
          fill="none"
          stroke="#2f80ec"
          stroke-linejoin="round"
          stroke-width="2"
          fill-rule="evenodd"
        />
        <motion.polygon
          class="arrowpathsmall"
          id="BottomLeft-2"
          data-name="BottomLeft"
          points="1.5 244.38 99.9 244.81 143.43 169.45 1.5 244.38"
          fill="none"
          stroke="#2f80ec"
          stroke-linejoin="round"
          stroke-width="2"
          fill-rule="evenodd"
        />
        <motion.polygon
          class="arrowpathsmall"
          id="MiddleRight-2"
          data-name="MiddleRight"
          points="265.03 170.51 169.44 74.59 150.07 144.88 265.03 170.51"
          fill="none"
          stroke="#2f80ec"
          stroke-linejoin="round"
          stroke-width="2"
          fill-rule="evenodd"
        />
        <motion.polygon
          class="arrowpathsmall"
          id="MiddleLeft-2"
          data-name="MiddleLeft"
          points="70.21 165.32 150.07 144.88 169.44 74.59 70.21 165.32"
          fill="none"
          stroke="#2f80ec"
          stroke-linejoin="round"
          stroke-width="2"
          fill-rule="evenodd"
        />
        <motion.polygon
          class="arrowpathsmall"
          id="TopRight-2"
          data-name="TopRight"
          points="258.39 61.97 174.56 1.5 168.64 56.83 258.39 61.97"
          fill="none"
          stroke="#2f80ec"
          stroke-linejoin="round"
          stroke-width="2"
          fill-rule="evenodd"
        />
        <motion.polygon
          class="arrowpathsmall"
          id="TopLeft-2"
          data-name="TopLeft"
          points="111 82.07 168.64 56.83 174.56 1.5 111 82.07"
          fill="none"
          stroke="#2f80ec"
          stroke-linejoin="round"
          stroke-width="2"
          fill-rule="evenodd"
        />
      </motion.svg>
    </>
  );
}
export default ArrowsUp;
