import { useTheme } from "styled-components";
import { useEffect, useState } from "react";
import ArrowsUp from "./ArrowsUp";
import logodark from "../assets/svg/logodark.svg";
import logolight from "../assets/svg/logolight.svg";

function Footer(props) {
  const theme = useTheme();
  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <div
      style={{
        backgroundColor: props.theme === "light" ? "#f2f2f2" : "#FFFFFF05",
        // boxShadow:
        //   props.theme === "light"
        //     ? "0px 0px 20px #00000015"
        //     : "0px 0px 20px #000",
        padding: props.desktop
          ? "80px 100px 80px 250px"
          : "40px 50px 50px 50px",
        // display:'flex',
        // flexDirection:'column',
        // alignSelf:'flex-end'
        width: "100%",
        marginTop: -100,
      }}>
      <div>
        <img
          src={theme.text != "#FAFAFA" ? logodark : logolight}
          width={150}
          height={140}
          style={{ opacity: 1 }}></img>
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <p
          style={{
            fontSize: 14,
            color: theme.text,
            opacity: 0.5,
          }}>
          © Ramiel Capital 2021 <strong>·</strong> Contact:
          interested@ramiel.capital
        </p>
      </div>
    </div>
  );
}
export default Footer;
