import { useTheme } from "styled-components";
import { useEffect, useState } from "react";
import { motion } from "framer-motion";

function BigPrism(props) {
  const theme = useTheme();
  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <>
      <motion.svg
        initial={{ scale: 0.9, rotate: "-10deg" }}
        animate={{ scale: 1, rotate: "0deg" }}
        transition={{ duration: 0.5, delay: 0.1 }}
        style={{
          width: 700,
          height: 700,
          position: "absolute",
        }}
        id="prism2"
        xmlns="http://www.w3.org/2000/svg"
        xlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 819.45 815.13">
        <defs>
          <radialGradient
            style={{ transition: "500ms ease" }}
            id="radial-gradient"
            cx="357.46"
            cy="347.58"
            fx="661.020573010014"
            fy="280.91781804669773"
            r="310.79"
            gradientUnits="userSpaceOnUse">
            <stop offset="0.1" stop-color="#1c5990" stop-opacity="0.73" />
            <stop offset="0.52" stop-color="#1c578d" stop-opacity="0.74" />
            <stop offset="0.67" stop-color="#1b5283" stop-opacity="0.76" />
            <stop offset="0.78" stop-color="#1a4872" stop-opacity="0.8" />
            <stop offset="0.87" stop-color="#183a59" stop-opacity="0.85" />
            <stop offset="0.94" stop-color="#162839" stop-opacity="0.92" />
            <stop offset="1" stop-color="#131416" />
          </radialGradient>
          <radialGradient
            style={{ transition: "500ms ease" }}
            id="radial-gradient-2"
            cx="334.88"
            cy="482.95"
            r="317.41"
            gradientUnits="userSpaceOnUse">
            <stop offset="0.16" stop-color="#091e4e" stop-opacity="0.73" />
            <stop offset="0.61" stop-color="#0a1d4b" stop-opacity="0.75" />
            <stop offset="0.78" stop-color="#0c1b40" stop-opacity="0.8" />
            <stop offset="0.91" stop-color="#0f182c" stop-opacity="0.89" />
            <stop offset="1" stop-color="#131416" />
          </radialGradient>
          <radialGradient
            style={{ transition: "500ms ease" }}
            id="radial-gradient-3"
            cx="476.49"
            cy="-73.6"
            r="245.04"
            gradientTransform="matrix(0.27, 1.05, 1.2, -0.31, 473.55, 33.42)"
            gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#263374" stop-opacity="0.49" />
            <stop offset="0.32" stop-color="#24306a" stop-opacity="0.54" />
            <stop offset="0.62" stop-color="#1e264c" stop-opacity="0.71" />
            <stop offset="0.9" stop-color="#14151a" stop-opacity="0.98" />
            <stop offset="0.92" stop-color="#131416" />
          </radialGradient>
          <radialGradient
            style={{ transition: "500ms ease" }}
            id="radial-gradient-4"
            cx="571.77"
            cy="363.34"
            fx="392.89440015982063"
            r="215.24"
            gradientTransform="matrix(-1.39, 0.7, 0.58, 1.44, 1091.18, -585.97)"
            gradientUnits="userSpaceOnUse">
            <stop offset="0.11" stop-color="#2980ce" stop-opacity="0.73" />
            <stop offset="0.23" stop-color="#287cc7" stop-opacity="0.74" />
            <stop offset="0.37" stop-color="#2670b3" stop-opacity="0.77" />
            <stop offset="0.52" stop-color="#225d92" stop-opacity="0.82" />
            <stop offset="0.67" stop-color="#1c4264" stop-opacity="0.89" />
            <stop offset="0.83" stop-color="#151f2a" stop-opacity="0.97" />
            <stop offset="0.88" stop-color="#131416" />
          </radialGradient>
          <radialGradient
            style={{ transition: "500ms ease" }}
            id="radial-gradientL"
            cx="256.22"
            cy="269.53"
            r="248.54"
            gradientUnits="userSpaceOnUse">
            <stop offset="0.1" stop-color="#1c5990" stop-opacity="0.73" />
            <stop offset="0.21" stop-color="#245f96" stop-opacity="0.74" />
            <stop offset="0.39" stop-color="#3871a6" stop-opacity="0.78" />
            <stop offset="0.6" stop-color="#5b8ec1" stop-opacity="0.85" />
            <stop offset="0.84" stop-color="#89b6e5" stop-opacity="0.94" />
            <stop offset="1" stop-color="#abd3ff" />
          </radialGradient>
          <radialGradient
            style={{ transition: "500ms ease" }}
            id="radial-gradientL-2"
            cx="345.8"
            cy="492.28"
            r="317.41"
            gradientUnits="userSpaceOnUse">
            <stop offset="0.16" stop-color="#2b5270" stop-opacity="0.73" />
            <stop offset="0.52" stop-color="#2c5271" stop-opacity="0.74" />
            <stop offset="0.68" stop-color="#2e5374" stop-opacity="0.78" />
            <stop offset="0.8" stop-color="#335479" stop-opacity="0.85" />
            <stop offset="0.9" stop-color="#395580" stop-opacity="0.94" />
            <stop offset="0.94" stop-color="#3d5684" />
          </radialGradient>
          <radialGradient
            style={{ transition: "500ms ease" }}
            id="radial-gradientL-3"
            cx="456.35"
            cy="-7.54"
            r="245.04"
            gradientTransform="matrix(-1.1, 0.55, 0.62, 1.06, 1095.76, 270.05)"
            gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#3a80af" stop-opacity="0.49" />
            <stop offset="0.24" stop-color="#3a80ae" stop-opacity="0.52" />
            <stop offset="0.45" stop-color="#3a7fab" stop-opacity="0.6" />
            <stop offset="0.66" stop-color="#3a7da5" stop-opacity="0.74" />
            <stop offset="0.86" stop-color="#3b7a9e" stop-opacity="0.93" />
            <stop offset="0.93" stop-color="#3b799b" />
          </radialGradient>
          <radialGradient
            style={{ transition: "500ms ease" }}
            id="radial-gradientL-4"
            cx="571.77"
            cy="363.34"
            fx="392.89440015982154"
            r="215.24"
            gradientTransform="matrix(-1.39, 0.7, 0.58, 1.44, 1090.72, -587.12)"
            gradientUnits="userSpaceOnUse">
            <stop offset="0.11" stop-color="#2980ce" stop-opacity="0.73" />
            <stop offset="0.26" stop-color="#2d83cf" stop-opacity="0.74" />
            <stop offset="0.43" stop-color="#388bd3" stop-opacity="0.79" />
            <stop offset="0.62" stop-color="#4b99d9" stop-opacity="0.86" />
            <stop offset="0.81" stop-color="#65ade1" stop-opacity="0.96" />
            <stop offset="0.88" stop-color="#70b5e5" />
          </radialGradient>
        </defs>
        <g id="Prism">
          <path
            id="BackBottomLeft"
            d="M15.91,428.59,421.6,820.13,390.53,369.77Z"
            transform="translate(-11.91 -9.03)"
            fill="none"
            stroke={theme.text === "#FAFAFA" ? "#000" : "#FFFFFF"}
            stroke-linejoin="bevel"
            stroke-width="8"
            style={{ transition: "500ms ease" }}
          />
          <path
            id="BackBottomRight"
            d="M827.36,434.47,421.58,820.16,390.53,369.77Z"
            transform="translate(-11.91 -9.03)"
            fill="none"
            stroke={theme.text === "#FAFAFA" ? "#000" : "#FFFFFF"}
            stroke-linejoin="bevel"
            stroke-width="8"
            style={{ transition: "500ms ease" }}
          />
          <path
            id="BackTopLeft"
            d="M15.91,428.59,445.45,13,390.53,369.77Z"
            transform="translate(-11.91 -9.03)"
            fill="none"
            stroke={theme.text === "#FAFAFA" ? "#000" : "#FFFFFF"}
            stroke-linejoin="bevel"
            stroke-width="8"
            style={{ transition: "500ms ease" }}
          />
          <path
            id="BackTopRight"
            d="M827.32,434.48,445.45,13,390.53,369.77Z"
            transform="translate(-11.91 -9.03)"
            fill="none"
            stroke={theme.text === "#FAFAFA" ? "#000" : "#FFFFFF"}
            stroke-linejoin="bevel"
            stroke-width="8"
            style={{ transition: "500ms ease" }}
          />
          <path
            id="FrontTopLeft"
            d="M15.91,428.59,445.45,13l51.09,513Z"
            transform="translate(-11.91 -9.03)"
            fill-opacity="0.72"
            stroke={theme.text === "#FAFAFA" ? "#131416" : "#FFFFFF"}
            stroke-linejoin="round"
            stroke-width="8"
            fill-rule="evenodd"
            fill={
              theme.text === "#FAFAFA"
                ? "url(#radial-gradient)"
                : "url(#radial-gradientL)"
            }
            style={{ transition: "500ms ease" }}
          />
          <path
            id="FrontBottomLeft"
            d="M15.91,428.59,421.6,820.13,496.54,526Z"
            transform="translate(-11.91 -9.03)"
            fill-opacity="0.81"
            stroke={theme.text === "#FAFAFA" ? "#131416" : "#FFFFFF"}
            stroke-linejoin="round"
            stroke-width="8"
            fill-rule="evenodd"
            fill={
              theme.text === "#FAFAFA"
                ? "url(#radial-gradient-2)"
                : "url(#radial-gradientL-2)"
            }
            style={{ transition: "500ms ease" }}
          />
          <path
            id="FrontBottomRight"
            d="M827.36,434.47,421.58,820.16l75-294.13Z"
            transform="translate(-11.91 -9.03)"
            stroke={theme.text === "#FAFAFA" ? "#131416" : "#FFFFFF"}
            stroke-linejoin="round"
            stroke-width="8"
            fill-rule="evenodd"
            fill={
              theme.text === "#FAFAFA"
                ? "url(#radial-gradient-3)"
                : "url(#radial-gradientL-3)"
            }
            style={{ transition: "500ms ease" }}
          />
          <path
            id="FrontTopRight"
            d="M827.32,434.48,445.45,13l51.09,513Z"
            transform="translate(-11.91 -9.03)"
            stroke={theme.text === "#FAFAFA" ? "#131416" : "#FFFFFF"}
            stroke-linejoin="round"
            stroke-width="8"
            fill-rule="evenodd"
            fill={
              theme.text === "#FAFAFA"
                ? "url(#radial-gradient-4)"
                : "url(#radial-gradientL-4)"
            }
            style={{ transition: "500ms ease" }}
          />
        </g>
      </motion.svg>
      <motion.svg
        initial={{ scale: 0.9, rotate: "-10deg" }}
        animate={{ scale: 1, rotate: "0deg" }}
        transition={{ duration: 0.5, delay: 0.1 }}
        style={{ width: 700, height: 700, position: "absolute" }}
        id="prism2"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 813.42 809.13">
        <motion.polygon
          class="path"
          id="FrontBottomLeft"
          points="1 416.56 406.67 808.13 481.63 513.99 1 416.56"
          fill="none"
          stroke="#2f80ec"
          stroke-linejoin="round"
          strokeDasharray="120"
          stroke-width="2"
          fill-rule="evenodd"
        />
        <motion.polygon
          class="path"
          id="FrontBottomRight"
          points="812.42 422.45 481.63 513.99 406.67 808.13 812.42 422.45"
          fill="none"
          stroke="#2f80ec"
          stroke-linejoin="round"
          stroke-width="2"
          fill-rule="evenodd"
        />
        <motion.polygon
          class="path"
          id="FrontTopRight"
          points="430.54 1 481.63 513.99 812.42 422.45 430.54 1"
          fill="none"
          stroke="#2f80ec"
          stroke-linejoin="round"
          stroke-width="2"
          fill-rule="evenodd"
        />
        <motion.polygon
          class="path"
          id="FrontTopLeft"
          points="1 416.56 430.54 1 481.63 513.99 1 416.56"
          fill="none"
          stroke="#2f80ec"
          stroke-linejoin="round"
          stroke-width="2"
          fill-rule="evenodd"
        />
      </motion.svg>
      <motion.svg
        initial={{ scale: 0.9, rotate: "-10deg" }}
        animate={{ scale: 1, rotate: "0deg" }}
        transition={{ duration: 0.5, delay: 0.1 }}
        style={{ width: 700, height: 700, position: "absolute" }}
        id="prism2"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 812.42 808.13">
        <motion.polygon
          class="path"
          id="BackBottomLeft"
          points="0.5 416.06 406.17 807.63 375.12 357.24 0.5 416.06"
          fill="none"
          stroke="#2f80ec"
          stroke-linejoin="round"
          fill-rule="evenodd"
          opacity="0.4"
        />
        <motion.polygon
          class="path"
          id="BackBottomRight"
          points="811.92 421.95 375.12 357.24 406.17 807.63 811.92 421.95"
          fill="none"
          stroke="#2f80ec"
          stroke-linejoin="round"
          fill-rule="evenodd"
          opacity="0.4"
        />
        <motion.polygon
          class="path"
          id="BackTopRight"
          points="430.04 0.5 375.12 357.24 811.92 421.95 430.04 0.5"
          fill="none"
          stroke="#2f80ec"
          stroke-linejoin="round"
          fill-rule="evenodd"
          opacity="0.4"
        />
        <motion.polygon
          class="path"
          id="BackTopLeft"
          points="0.5 416.06 430.04 0.5 375.12 357.24 0.5 416.06"
          fill="none"
          stroke="#2f80ec"
          stroke-linejoin="round"
          fill-rule="evenodd"
          opacity="0.4"
        />
      </motion.svg>
    </>
  );
}
export default BigPrism;
